exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cennik-js": () => import("./../../../src/pages/cennik.js" /* webpackChunkName: "component---src-pages-cennik-js" */),
  "component---src-pages-index-2-js": () => import("./../../../src/pages/index2.js" /* webpackChunkName: "component---src-pages-index-2-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-nasz-zespol-js": () => import("./../../../src/pages/nasz-zespol.js" /* webpackChunkName: "component---src-pages-nasz-zespol-js" */),
  "component---src-pages-uslugi-chirurgia-stomatologiczna-js": () => import("./../../../src/pages/uslugi/chirurgia-stomatologiczna.js" /* webpackChunkName: "component---src-pages-uslugi-chirurgia-stomatologiczna-js" */),
  "component---src-pages-uslugi-endodoncja-js": () => import("./../../../src/pages/uslugi/endodoncja.js" /* webpackChunkName: "component---src-pages-uslugi-endodoncja-js" */),
  "component---src-pages-uslugi-index-js": () => import("./../../../src/pages/uslugi/index.js" /* webpackChunkName: "component---src-pages-uslugi-index-js" */),
  "component---src-pages-uslugi-ortodoncja-js": () => import("./../../../src/pages/uslugi/ortodoncja.js" /* webpackChunkName: "component---src-pages-uslugi-ortodoncja-js" */),
  "component---src-pages-uslugi-protetyka-js": () => import("./../../../src/pages/uslugi/protetyka.js" /* webpackChunkName: "component---src-pages-uslugi-protetyka-js" */),
  "component---src-pages-uslugi-stomatologia-zachowawcza-i-estetyczna-js": () => import("./../../../src/pages/uslugi/stomatologia-zachowawcza-i-estetyczna.js" /* webpackChunkName: "component---src-pages-uslugi-stomatologia-zachowawcza-i-estetyczna-js" */),
  "component---src-pages-uslugi-wybielanie-zebow-js": () => import("./../../../src/pages/uslugi/wybielanie-zebow.js" /* webpackChunkName: "component---src-pages-uslugi-wybielanie-zebow-js" */),
  "component---src-pages-uslugi-zabiegi-higieniczne-js": () => import("./../../../src/pages/uslugi/zabiegi-higieniczne.js" /* webpackChunkName: "component---src-pages-uslugi-zabiegi-higieniczne-js" */),
  "component---src-pages-wizyta-js": () => import("./../../../src/pages/wizyta.js" /* webpackChunkName: "component---src-pages-wizyta-js" */)
}

